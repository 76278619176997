import { atom } from "recoil";

export interface ISidebarAtom {
  isCollapsed: boolean;
}

export const sidebarAtom = atom({
  key: "sidebarAtom",
  default: {
    isCollapsed: false,
  } as ISidebarAtom,
});
