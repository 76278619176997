"use client";

import Image from "next/image";
import { useRecoilValue } from "recoil";
import classNames from "classnames";
import PerfectScrollbar from "react-perfect-scrollbar";

import { sidebarAtom } from "@app/store/atoms/sidebarAtoms";
import { sidebar } from "@app/config/sidebar";

import SidebarNavigation from "@app/components/layout/SidebarNavigation/SidebarNavigation";

const brandLogo =
  "https://static.wixstatic.com/media/572ed0_bb236eac2efb45bc98a1c18ad2b4807f~mv2.png/v1/fill/w_398,h_100,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/cabin-select-logo.png";

export default function SidebarComponent() {
  const { isCollapsed } = useRecoilValue(sidebarAtom);

  return (
    <nav className={classNames("sidebar", { collapsed: isCollapsed })}>
      <div className="sidebar-content">
        <PerfectScrollbar>
          <a className="sidebar-brand" href="/dashboard">
            <Image
              src={brandLogo}
              width={200}
              height={50}
              quality={100}
              alt="brand logo"
              priority
            />
          </a>

          <SidebarNavigation depth={0} pages={sidebar} />
        </PerfectScrollbar>
      </div>
    </nav>
  );
}
