import { useParams } from "next/navigation";

import { SidebarConfigType } from "@app/config/sidebar";

import SidebarNavigationItem from "@app/components/layout/SidebarNavigationItem/SidebarNavigationItem";

export default function SidebarNavigation({
  depth,
  pages,
}: {
  depth: number;
  pages: SidebarConfigType;
}) {
  const params = useParams();

  return (
    <ul className="sidebar-nav">
      {pages.map((page) => {
        const pageChildrenPathnames =
          page.pageChildren?.map((child) => child.href) ?? [];

        const isOpen = pageChildrenPathnames.includes(
          `/${params?.type}/${params?.list}` ?? ""
        );

        const isActive = page.href === `/${params?.type}/${params?.list}`;

        if (page.pageChildren) {
          return (
            <SidebarNavigationItem
              key={page.title}
              isOpen={isOpen}
              depth={depth}
              icon={page.icon}
              title={page.title}
              href={page.href}
            >
              <SidebarNavigation depth={depth + 1} pages={page.pageChildren} />
            </SidebarNavigationItem>
          );
        }

        return (
          <SidebarNavigationItem
            key={page.title}
            isActive={isActive}
            depth={depth}
            icon={page.icon}
            title={page.title}
            href={page.href}
            isHidden={page.hidden}
          />
        );
      })}
    </ul>
  );
}
