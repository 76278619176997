"use client";

import { AnimatePresence, motion } from "framer-motion";

export default function GlobalAnimationProvider({
  children,
}: Readonly<{
  children: React.ReactNode;
}>) {
  return (
    <AnimatePresence mode="wait">
      <motion.div
        className="w-100"
        initial={{ y: 10, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ ease: "easeInOut", duration: 0.5 }}
        layout
      >
        {children}
      </motion.div>
    </AnimatePresence>
  );
}
