import(/* webpackMode: "eager" */ "/workspace/node_modules/next/font/google/target.css?{\"path\":\"src/app/(main)/layout.tsx\",\"import\":\"Poppins\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"300\",\"400\",\"500\"]}],\"variableName\":\"poppins\"}");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/react-loading-skeleton/dist/skeleton.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/src/components/layout/ErrorBoundary/ErrorBoundary.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/src/components/layout/Navbar/Navbar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/src/components/layout/Sidebar/Sidebar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/src/providers/GlobalAnimationProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/src/providers/RecoilProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/src/providers/UserProvider.tsx");
;
import(/* webpackMode: "eager" */ "/workspace/src/styles/_app.scss");
;
import(/* webpackMode: "eager" */ "/workspace/src/styles/globals.scss");
