import * as Icons from "react-feather";

const iconMapping: Record<string, Icons.Icon> = {
  edit: Icons.Edit,
  trash2: Icons.Trash2,
  plusCircle: Icons.PlusCircle,
  trendingDown: Icons.TrendingDown,
  image: Icons.Image,
  archive: Icons.Archive,
  trendingUp: Icons.TrendingUp,
  x: Icons.X,
  xCircle: Icons.XCircle,
  key: Icons.Key,
  map: Icons.Map,
  refreshCw: Icons.RefreshCw,
  upload: Icons.Upload,
  info: Icons.Info,
  alertTriangle: Icons.AlertTriangle,
  chevronLeft: Icons.ChevronLeft,
  bookOpen: Icons.BookOpen,
  dollarSign: Icons.DollarSign,
  bookmark: Icons.Bookmark,
  fileText: Icons.FileText,
  users: Icons.Users,
  eye: Icons.Eye,
  eyeOff: Icons.EyeOff,
  copy: Icons.Copy,
};

export default function CustomIcon({
  type,
  size,
  className,
}: {
  type: string;
  size?: number;
  className?: string;
}) {
  const IconByType = iconMapping[type] ?? "div";

  return <IconByType className={className ?? "feather"} size={size} />;
}
