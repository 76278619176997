import {
  Layout,
  Sliders,
  DollarSign,
  Compass,
  Settings,
  Briefcase,
  Activity,
} from "react-feather";

export interface ISidebarItem {
  href: string;
  icon?: React.FC<any>;
  title: string;
  hidden?: boolean;
  pageChildren?: {
    href: string;
    title: string;
    hidden?: boolean;
  }[];
}

export type SidebarConfigType = ISidebarItem[];

export const SIDEBAR_TYPES = {
  CNF: "cnf",
  OPS: "ops",
  DASHBOARD: "dashboard",
  REPORTS: "reports",
} as const;

export const SIDEBAR_LISTS = {
  AGE: "age",
  BED_CONFIG: "bed-config",
  BRAND: "brand",
  CABIN_ATTRIBUTE: "cabin-attribute",
  CABIN_HOLD_DURATION: "cabin-hold-duration",
  CANCELLATION_POLICY: "cancellation-policy",
  CATEGORY: "category",
  CONSORTIA: "consortia",
  CRUISE_TAG: "cruise-tag",
  CURRENCY: "currency",
  DEPOSIT_POLICY: "deposit-policy",
  DINING_SEATING: "dining-seating",
  DINING_TABLE: "dining-table",
  GROUP_REVIEW: "group-review",
  IMAGE_SIZE: "image-size",
  LANGUAGE: "language",
  MARKET: "market",
  OPTIONAL_EXTRA_TAG: "optional-extra-tag",
  OPTION_TIME: "option-time",
  PORT: "port",
  CONFIGURABLE_FIELD_GROUP: "configurable-field-group",
  CONFIGURABLE_FIELD: "configurable-field",
  SALES_AREA: "sales-area",
  SHIP: "ship",
  GRADE: "grade",
  CRUISE: "cruise",
  RATE: "rate",
  PROMOTION: "promotion",
  OPTIONAL_EXTRA: "optional-extra",
  AGENCY: "agency",
  AGENT: "agent",
  RESERVATION: "reservation",
  REPORTS: "reports",
  AVAILABILITY: "availability",
} as const;

export const sidebar: SidebarConfigType = [
  {
    href: `/${SIDEBAR_TYPES.CNF}`,
    icon: Settings,
    title: "Configure",
    pageChildren: [
      {
        href: `/${SIDEBAR_TYPES.CNF}/${SIDEBAR_LISTS.AGE}`,
        title: "Age",
      },
      {
        href: `/${SIDEBAR_TYPES.CNF}/${SIDEBAR_LISTS.BED_CONFIG}`,
        title: "Bed Configuration",
      },
      {
        href: `/${SIDEBAR_TYPES.CNF}/${SIDEBAR_LISTS.BRAND}`,
        title: "Brand",
      },
      {
        href: `/${SIDEBAR_TYPES.CNF}/${SIDEBAR_LISTS.CABIN_ATTRIBUTE}`,
        title: "Cabin Attribute",
      },
      {
        href: `/${SIDEBAR_TYPES.CNF}/${SIDEBAR_LISTS.CABIN_HOLD_DURATION}`,
        title: "Cabin Hold Duration",
      },
      {
        href: `/${SIDEBAR_TYPES.CNF}/${SIDEBAR_LISTS.CANCELLATION_POLICY}`,
        title: "Cancellation Policy",
      },
      {
        href: `/${SIDEBAR_TYPES.CNF}/${SIDEBAR_LISTS.CATEGORY}`,
        title: "Category",
      },
      {
        href: `/${SIDEBAR_TYPES.CNF}/${SIDEBAR_LISTS.CONSORTIA}`,
        title: "Consortia",
      },
      {
        href: `/${SIDEBAR_TYPES.CNF}/${SIDEBAR_LISTS.CRUISE_TAG}`,
        title: "Cruise Tags",
      },
      {
        href: `/${SIDEBAR_TYPES.CNF}/${SIDEBAR_LISTS.CURRENCY}`,
        title: "Currency",
      },
      {
        href: `/${SIDEBAR_TYPES.CNF}/${SIDEBAR_LISTS.DEPOSIT_POLICY}`,
        title: "Deposit Policy",
      },
      {
        href: `/${SIDEBAR_TYPES.CNF}/${SIDEBAR_LISTS.DINING_SEATING}`,
        title: "Dining Seating",
      },
      {
        href: `/${SIDEBAR_TYPES.CNF}/${SIDEBAR_LISTS.DINING_TABLE}`,
        title: "Dining Table",
      },
      {
        href: `/${SIDEBAR_TYPES.CNF}/${SIDEBAR_LISTS.GROUP_REVIEW}`,
        title: "Group Review",
      },
      {
        href: `/${SIDEBAR_TYPES.CNF}/${SIDEBAR_LISTS.IMAGE_SIZE}`,
        title: "Image Size",
      },
      {
        href: `/${SIDEBAR_TYPES.CNF}/${SIDEBAR_LISTS.LANGUAGE}`,
        title: "Language",
      },
      {
        href: `/${SIDEBAR_TYPES.CNF}/${SIDEBAR_LISTS.MARKET}`,
        title: "Market",
      },
      {
        href: `/${SIDEBAR_TYPES.CNF}/${SIDEBAR_LISTS.OPTIONAL_EXTRA_TAG}`,
        title: "Optional Extra Tags",
      },
      {
        href: `/${SIDEBAR_TYPES.CNF}/${SIDEBAR_LISTS.OPTION_TIME}`,
        title: "Option Time",
      },
      {
        href: `/${SIDEBAR_TYPES.CNF}/${SIDEBAR_LISTS.PORT}`,
        title: "Port",
      },
      {
        href: `/${SIDEBAR_TYPES.CNF}/${SIDEBAR_LISTS.CONFIGURABLE_FIELD_GROUP}`,
        title: "Configurable Field Group",
      },
      {
        href: `/${SIDEBAR_TYPES.CNF}/${SIDEBAR_LISTS.CONFIGURABLE_FIELD}`,
        title: "Configurable Field",
      },
      {
        href: `/${SIDEBAR_TYPES.CNF}/${SIDEBAR_LISTS.SALES_AREA}`,
        title: "Sales Area",
      },
    ],
  },
  {
    href: `/${SIDEBAR_TYPES.OPS}`,
    icon: Layout,
    title: "Build",
    pageChildren: [
      {
        href: `/${SIDEBAR_TYPES.OPS}/${SIDEBAR_LISTS.SHIP}`,
        title: "Ship",
      },
      {
        href: `/${SIDEBAR_TYPES.OPS}/${SIDEBAR_LISTS.GRADE}`,
        title: "Grade",
      },
      {
        href: `/${SIDEBAR_TYPES.OPS}/${SIDEBAR_LISTS.CRUISE}`,
        title: "Cruise",
      },
    ],
  },
  {
    href: `/${SIDEBAR_TYPES.OPS}`,
    icon: DollarSign,
    title: "Price",
    pageChildren: [
      {
        href: `/${SIDEBAR_TYPES.OPS}/${SIDEBAR_LISTS.RATE}`,
        title: "Rate",
      },
      {
        href: `/${SIDEBAR_TYPES.OPS}/${SIDEBAR_LISTS.PROMOTION}`,
        title: "Promotion",
      },
    ],
  },
  {
    href: `/${SIDEBAR_TYPES.OPS}`,
    icon: Compass,
    title: "Optional Extras",
    pageChildren: [
      {
        href: `/${SIDEBAR_TYPES.OPS}/${SIDEBAR_LISTS.OPTIONAL_EXTRA}`,
        title: "Optional Extras",
      },
    ],
  },
  {
    href: `/${SIDEBAR_TYPES.OPS}`,
    icon: Sliders,
    title: "Operations",
    pageChildren: [
      {
        href: `/${SIDEBAR_TYPES.OPS}/${SIDEBAR_LISTS.AGENCY}`,
        title: "Agency",
      },
      {
        href: `/${SIDEBAR_TYPES.OPS}/${SIDEBAR_LISTS.AGENT}`,
        title: "Agent",
        hidden: true,
      },
    ],
  },
  {
    href: `/${SIDEBAR_TYPES.OPS}`,
    icon: Briefcase,
    title: "Reservations",
    pageChildren: [
      {
        href: `/${SIDEBAR_TYPES.OPS}/${SIDEBAR_LISTS.RESERVATION}`,
        title: "Reservation",
      },
    ],
  },
  {
    href: `/${SIDEBAR_TYPES.DASHBOARD}`,
    icon: Activity,
    title: "Reports",
    pageChildren: [
      {
        href: `/${SIDEBAR_TYPES.DASHBOARD}/${SIDEBAR_LISTS.REPORTS}`,
        title: "Availability Reports",
      },
    ],
  },
];
