import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

interface ILoaderProps {
  width?: string | number;
  height?: string | number;
  count?: number;
  className?: string;
}

export default function Loader({
  width,
  height,
  count,
  className,
}: ILoaderProps) {
  return (
    <SkeletonTheme
      width={width ?? 140}
      height={height ?? 20}
      baseColor="var(--bs-gray-200)"
      highlightColor="var(--bs-gray-100)"
    >
      <Skeleton className={className ?? ""} count={count ?? 1} />
    </SkeletonTheme>
  );
}
