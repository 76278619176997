"use client";

import { ReactNode, useEffect } from "react";
import { useSetRecoilState } from "recoil";
import Cookies from "js-cookie";

import {
  V4_ADMIN_USER_CLIENT,
  V4_ADMIN_USER_NAME,
  V4_ADMIN_USER_ID,
  V4_ADMIN_USER_USERNAME,
  V4_ADMIN_DEFAULT_LANGUAGE,
} from "@app/utils/constants/global";

import { IUserAtom, userAtom } from "@app/store/atoms/userAtoms";

interface IUserProviderProps {
  children: ReactNode;
}

export default function UserProvider({ children }: IUserProviderProps) {
  const setUser = useSetRecoilState(userAtom);

  useEffect(() => {
    const cookieUserClient = Cookies.get(V4_ADMIN_USER_CLIENT);
    const cookieUserName = Cookies.get(V4_ADMIN_USER_NAME);
    const cookieUserId = Cookies.get(V4_ADMIN_USER_ID);
    const cookieUserUsername = Cookies.get(V4_ADMIN_USER_USERNAME);
    const cookieDefaultLanguage = Cookies.get(V4_ADMIN_DEFAULT_LANGUAGE);

    const isUserExistInCookie =
      cookieUserClient &&
      cookieUserName &&
      cookieUserId &&
      cookieUserUsername &&
      cookieDefaultLanguage;

    if (isUserExistInCookie) {
      const userPayload: IUserAtom = {
        client: cookieUserClient,
        name: cookieUserName,
        user_id: cookieUserId,
        username: cookieUserUsername,
        language: cookieDefaultLanguage,
      };

      setUser(userPayload);
    } else {
      (async () => {
        const response = await fetch("/api/user");
        const {
          data: { user: fetchedUser },
        }: { data: { user: IUserAtom } } = await response.json();

        Cookies.set(V4_ADMIN_USER_CLIENT, fetchedUser.client);
        Cookies.set(V4_ADMIN_USER_NAME, fetchedUser.name);
        Cookies.set(V4_ADMIN_USER_ID, fetchedUser.user_id);
        Cookies.set(V4_ADMIN_USER_USERNAME, fetchedUser.username);
        Cookies.set(V4_ADMIN_DEFAULT_LANGUAGE, fetchedUser.language);

        const userPayload: IUserAtom = {
          client: fetchedUser.client,
          name: fetchedUser.name,
          user_id: fetchedUser.user_id,
          username: fetchedUser.username,
          language: fetchedUser.language,
        };

        setUser(userPayload);
      })();
    }
  }, []);

  return <>{children}</>;
}
