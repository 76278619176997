import { atom } from "recoil";

export interface IUserAtom {
  client: string;
  name: string;
  user_id: string;
  username: string;
  language: string;
}

export const userAtom = atom({
  key: "userAtom",
  default: {
    client: "",
    name: "",
    user_id: "",
    username: "",
    language: "",
  } as IUserAtom,
});
