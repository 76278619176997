import CustomIcon from "@app/components/ui/CustomIcon";

export default function Alert({
  variant,
  icon,
  content,
}: {
  variant: string;
  icon: string;
  content: string;
}) {
  return (
    <div
      role="alert"
      className={`alert-outline-coloured alert alert-${variant}`}
    >
      {icon && (
        <div className="alert-icon">
          <CustomIcon type={icon} />
        </div>
      )}

      <span className="alert-message">{content}</span>
    </div>
  );
}
