import { PropsWithChildren, useState } from "react";
import classNames from "classnames";
import { Collapse } from "react-bootstrap";

import { ISidebarItem } from "@app/config/sidebar";

export default function SidebarNavigationItem({
  isOpen,
  isActive,
  depth,
  icon: Icon,
  title,
  href,
  children,
  isHidden,
}: PropsWithChildren<
  ISidebarItem & {
    depth: number;
    isOpen?: boolean;
    isActive?: boolean;
    isHidden?: boolean;
  }
>) {
  const [open, setOpen] = useState(isOpen);

  const handleToggle = () => {
    setOpen((state) => !state);
  };

  if (children) {
    return (
      <li className={classNames("sidebar-item", { active: open })}>
        <a
          className={classNames("sidebar-link", { collapsed: !open })}
          data-bs-toggle="collapse"
          aria-expanded={open}
          data-depth={depth}
          onClick={handleToggle}
        >
          {Icon && <Icon className="feather align-middle" />}

          <span className="align-middle" data-depth={depth}>
            {title}
          </span>
        </a>

        <Collapse in={open}>
          <ul className="sidebar-dropdown list-unstyled">{children}</ul>
        </Collapse>
      </li>
    );
  }

  return (
    <li
      className={classNames("sidebar-item", {
        active: isActive,
        "d-none": isHidden,
      })}
    >
      <a data-depth={depth} href={href} className="sidebar-link">
        {Icon && <Icon className="feather align-middle" />}

        <span className="align-middle" data-depth={depth}>
          {title}
        </span>
      </a>
    </li>
  );
}
