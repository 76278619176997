"use client";

import { Navbar, Nav, Dropdown } from "react-bootstrap";
import { useRecoilState, useRecoilValue } from "recoil";
import Cookies from "js-cookie";
import { useRouter } from "next/navigation";

import { sidebarAtom } from "@app/store/atoms/sidebarAtoms";
import { userAtom } from "@app/store/atoms/userAtoms";

import {
  V4_ADMIN_DEFAULT_LANGUAGE,
  V4_ADMIN_USER_CLIENT,
  V4_ADMIN_USER_ID,
  V4_ADMIN_USER_NAME,
  V4_ADMIN_USER_USERNAME,
} from "@app/utils/constants/global";

import Loader from "@app/components/ui/Loader";

const NavbarComponent = () => {
  const router = useRouter();

  const [{ isCollapsed }, setSidebarState] = useRecoilState(sidebarAtom);
  const { name } = useRecoilValue(userAtom);

  const handleCollapse = () => {
    setSidebarState({ isCollapsed: !isCollapsed });
  };

  const handleLogout = async () => {
    await fetch("/api/logout");

    Cookies.remove(V4_ADMIN_USER_CLIENT);
    Cookies.remove(V4_ADMIN_USER_NAME);
    Cookies.remove(V4_ADMIN_USER_ID);
    Cookies.remove(V4_ADMIN_USER_USERNAME);
    Cookies.remove(V4_ADMIN_DEFAULT_LANGUAGE);

    localStorage.removeItem("configAtom");
    router.refresh();
  };

  return (
    <Navbar variant="light" className="navbar-bg">
      <span className="sidebar-toggle d-flex" onClick={handleCollapse}>
        <i className="hamburger align-self-center" />
      </span>

      <Navbar.Collapse>
        <Nav className="navbar-align">
          <Dropdown className="nav-item" align="end">
            <span>
              {name ? (
                <Dropdown.Toggle as="a" className="nav-link">
                  <span className="text-dark">{name}</span>
                </Dropdown.Toggle>
              ) : (
                <Loader height={33} />
              )}
            </span>

            <Dropdown.Menu>
              <Dropdown.Item onClick={handleLogout}>Sign out</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavbarComponent;
